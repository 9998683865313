import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import LandingContent from '../components/landing-content'
import Hero from '../components/hero'
import styled from 'styled-components'
export const query = graphql`
  query IndexPageQuery {
      site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      id
      title
      landingTitle
      landingExcerpt
      description
      _rawReferencesText
      _rawTeam
      _rawBusinessUnitText
      teamImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
      businessUnit {
        mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
      }
      referenceProject{
        mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
      }
    }
  }
`

const Divider = styled.div`
width: 100%;
border-top:1px solid white;
height: 2em;
@media (max-width:600px){
height: 4em;

}
`
const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const site = (data || {}).site
  return (
    <Layout>
      <SEO title={site.landingTitle} description={site.description} />
      <Container>
        <Hero excerpt={site.landingExcerpt} title={site.landingTitle} />
        <Divider />
        <LandingContent key='Business-Units' slug='/business-units/' title='Business Units' _rawBody={site._rawBusinessUnitText} images={site.businessUnit} />
        <LandingContent key='References' slug='/references/' title='References' _rawBody={site._rawReferencesText} images={site.referenceProject} />
        <LandingContent key='Team' slug='/team/' title='Team' _rawBody={site._rawTeam} image={site.teamImage} />
      </Container>
    </Layout>
  )
}

export default IndexPage
