import React, {useState} from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import {responsiveTitle2Bold} from '../components/typography.module.css'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import BlockContent from './block-content'
import Fade from 'react-reveal/Fade'
import RenderInBrowser from 'react-render-in-browser'

const StyledFade = styled(Fade)`


`
const Wrapper = styled.section`
  padding: 2em 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2em;
  align-items: center;
  grid-template-areas: 
    "image image text text";
  >div:nth-child(2){  
    grid-area: text;
    align-self: center;
    display:grid;
    grid-template-columns: 1fr;
    grid-template-rows:1fr;
    grid-template-areas: "txt";
  }
  >div>div{
    background-color:var(--color-dark-gray);
    padding: 2rem;
    grid-area:txt;
  }
  >div:nth-child(1), figure{  
    grid-area: image;
    display:grid;
    grid-template-columns: 1fr;
    grid-template-rows:1fr;
    grid-template-areas: "img";
  }
  img{
    grid-area: img;
  }

  :nth-child(odd){
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: 
      "text text image image "
      "text text image image ";
    }
  }
  :nth-child(odd) .second{
      transform:translateX(-20px)translateY(-20px);
  }
  :nth-child(odd) .third{
      transform:translateX(-40px)translateY(-40px);
  }
  @media (max-width:900px){
        grid-column-gap: 1em;
  }

  @media (max-width:600px) {
    height:auto;
    grid-template-columns: 1fr;
    grid-template-areas: 
    "image"
    "text";
    margin-bottom: 2em;
    grid-template-rows: auto auto;
    grid-row-gap:1em;
  :nth-child(odd) .second{
      transform:translateX(-20px)translateY(20px);
  }
  :nth-child(odd) .third{
      transform:translateX(-40px)translateY(40px);
  }
    :nth-child(odd){
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    align-items: start;
    grid-template-areas: 
      "image"      
      "text";
  }
  }
  `

const First = styled.div`
  z-index: 3;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.3), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
`
const Second = styled.div`
  h2,p,a{color:var(--color-dark-gray);}
  a{border-bottom: 1px solid var(--color-dark-gray);}
  transform:translateX(20px)translateY(-20px);
  opacity: 0.4;
  z-index: 2;
  @media (max-width:600px) {
    transform:translateX(20px)translateY(20px);
  }
`
const Third = styled.div`
  h2,p,a{color:var(--color-dark-gray);}
  a{border-bottom: 1px solid var(--color-dark-gray);}
  transform:translateX(40px)translateY(-40px);
  opacity: 0.1;
  z-index: 2;
  @media (max-width:600px) {
    transform:translateX(40px)translateY(40px);
  }
`
const StyledLink = styled(Link)`
  border-bottom: 1px solid white;
  :hover{
    color: var(--color-accent)
  }
`
const Figure = styled.figure`
  transform:translateX(50px)translateY(50px);
`
const Image = styled.img`
  width: 100%;
  align-self: center;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.3), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  z-index:8;
  
`
const GalleryImage = styled(Image)`
  z-index:${props => props.order};
  transform-style:preserve-3d;
  cursor: pointer;
  transform:  perspective(500px)scale(1)translateX(calc(${props => props.order}*-20px))translateY(calc(${props => props.order}*-20px))translateZ(calc(${props => props.order}*10px));
  position: relative;
  opacity:calc((${props => props.order}+0.5)/${props => props.length});
`

function CascadingGallery (props) {
  const {images, length} = props
  const [count, setCount] = useState(0)
  console.log(length)
  return (
    <Figure onClick={() => setCount(count + 1)}>
      {images.map((image, index) => (
        <>
          <GalleryImage
            src={imageUrlFor(buildImageObj(image.mainImage))
              .width(500)
              .height(300)
              .fit('crop')
              .url()}
            alt={image.mainImage}
            order={(count + index) % (length) + 1}
            length={length}
          />
        </>
      ))}
    </Figure>
  )
}
function LandingContent (props) {
  const {title, _rawBody, images, image, slug} = props

  return (
    <>
      <RenderInBrowser except safari ie>

        <Wrapper>
          <StyledFade>
            <div >
              {image && <Image
                src={imageUrlFor(buildImageObj(image))
                  .width(500)
                  .fit('crop')
                  .url()}
                alt={image}
              />
              }
              {images && <CascadingGallery images={images} length={images.length} />}
            </div>
            <div>
              <First>
                <Link to={slug}>
                  <h2 className={responsiveTitle2Bold}>{title}</h2>
                  {_rawBody && <BlockContent blocks={_rawBody || []} />}
                  <StyledLink to={slug}>More Information</StyledLink>
                </Link>
              </First>
              <Second className='second'>
                <h2 className={responsiveTitle2Bold}>{title}</h2>
                {_rawBody && <BlockContent blocks={_rawBody || []} />}
                <StyledLink to={slug}>More Information</StyledLink>
              </Second>
              <Third className='third'>
                <h2 className={responsiveTitle2Bold}>{title}</h2>
                {_rawBody && <BlockContent blocks={_rawBody || []} />}
                <StyledLink to={slug}>More Information</StyledLink>
              </Third>
            </div>
          </StyledFade>
        </Wrapper>
      </RenderInBrowser>
      <RenderInBrowser only safari ie>
        <Wrapper>
          <div >
            {image && <Image
              src={imageUrlFor(buildImageObj(image))
                .width(500)
                .fit('crop')
                .url()}
              alt={image}
            />
            }
            {images && <CascadingGallery images={images} length={images.length} />}
          </div>
          <div>
            <First>
              <Link to={slug}>
                <h2 className={responsiveTitle2Bold}>{title}</h2>
                {_rawBody && <BlockContent blocks={_rawBody || []} />}
                <StyledLink to={slug}>More Information</StyledLink>
              </Link>
            </First>
            <Second className='second'>
              <h2 className={responsiveTitle2Bold}>{title}</h2>
              {_rawBody && <BlockContent blocks={_rawBody || []} />}
              <StyledLink to={slug}>More Information</StyledLink>
            </Second>
            <Third className='third'>
              <h2 className={responsiveTitle2Bold}>{title}</h2>
              {_rawBody && <BlockContent blocks={_rawBody || []} />}
              <StyledLink to={slug}>More Information</StyledLink>
            </Third>
          </div>
        </Wrapper>
      </RenderInBrowser>

    </>
  )
}

export default LandingContent
